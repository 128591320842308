







































import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedPlanHeader from "@/components/Shared/Plan/Header.vue";
import SharedPlanListItem from "@/components/Shared/Plan/ListItemOneRow.vue";
import WebOthersNavigationBar from "@/components/Web/Others/NavigationBar.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseWrapper,
        SharedPlanListItem,
        WebOthersNavigationBar,
        SharedPlanHeader,
    },
})
export default class WebCompanies extends Vue {
    get currentYearPlans() {
        return this.$store.state.plans.currentYearPlans;
    }

    async getPlans() {
        const year = new Date().getFullYear();
        const plans = await this.$store.dispatch(
            "plans/getCurrentYearPlans",
            year
        );

        return plans;
    }

    async mounted() {
        await this.getPlans();
    }
}
