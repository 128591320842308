




































































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import { toCurrency, toDisplayName } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
    },
})
export default class AdminUserListItemOneRow extends Vue {
    @Prop() plan!: any;
    @Prop() isWeb!: boolean;

    get me() {
        return this.$store.state.users.me;
    }

    get isCreator() {
        const hasUser = this.me;
        const hasPlan = this.plan && this.plan.user;
        const planUserId = hasPlan ? this.plan.user.id : null;

        if (!hasUser || !hasPlan) return false;

        return this.me.id === planUserId;
    }

    toDisplayName(user: any) {
        return toDisplayName(user)
    }

    goToUser(user: any) {
        let route: string = "/app/perusahaan/";
        const hasUserId = user && user.id;
        if (this.isWeb) route = "/perusahaan/";
        if (!hasUserId) return;

        if (user.role.name === "PROPOSER") route = "/app/pengusul/";
        return route + user.id;
    }

    toCurrency(value: any) {
        return toCurrency(value);
    }

    editPlan() {
        this.$router.push("/app/rencana/" + this.plan.id + "/edit");
    }
}
